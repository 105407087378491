import BG_LOCALE from './bg.json';
import EN_LOCALE from './en.json';
import RU_LOCALE from './ru.json';

export const bg = {
  ...BG_LOCALE
};

export const en = {
  ...EN_LOCALE
};

export const ru = {
  ...RU_LOCALE
};
