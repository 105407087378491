import './Tickets.css';

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../../config';
import { Head, PageBackground, Title, CloseButton } from '../../components';
import { pageCtrl } from '../../utils/common';
import myDispatch from '../../store/dispatcher';
import { useTranslation } from 'react-i18next';

const { CDN_URL } = config;

function Tickets({ contRef, page }) {
  const params = useParams();
  const history = useHistory();
  const elRef = useRef(null);
  const refAnimation = useRef(null);
  const [loadState, setLoadState] = useState(false);
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const { dispatchCloseBtnVisibility } = myDispatch();
  const { t } = useTranslation();
  const cmd = pageCtrl({ elRef, contRef });
  let timerOnLoad = null;

  useEffect(() => {
    setIsCurrentPage(params.page === page.slug);
    const t1 = setTimeout(setLoadState(true), 150);
    if (isCurrentPage) dispatchCloseBtnVisibility(true);
    return () => {
      clearTimeout(t1);
      clearTimeout(timerOnLoad);
      dispatchCloseBtnVisibility(false);
      setIsCurrentPage(false);
    };
  }, [params]);

  const openAnimation = (cb = null) => {
    if (elRef.current.classList.contains('s--active')) return;
    if (cb !== null) cb();
    cmd.show();
    setTimeout(() => {
      setLoadState(true);
      refAnimation.current.classList.add('active');
      cmd.accordion();
    }, 1.1e3);
  };

  const onReload = () => (isCurrentPage ? openAnimation() : null);

  const changeUrl = () => {
    cmd.goTo(`/${page.slug}`);
  };

  timerOnLoad = setTimeout(onReload, 2e2);

  const elClick = () => openAnimation(changeUrl);

  const closeClick = () => cmd.close({ setIsCurrentPage, setLoadState });

  const goToTickets = () => {
    history.push(`/${t('tickets latin').toLowerCase()}`);
  };

  const goToShop = () => {
    window.open('https://bulgarianrosemagic.bg/', '_blank');
  };
  return (
    <div className="el" ref={elRef} onClick={elClick}>
      {loadState && <Head data={page} />}
      <div className="el__overflow">
        <div className="el__inner">
          <PageBackground banner={page.defaultBanner} />
          <Title text={page.title} />
          {loadState && (
            <div className={`el__content`}>
              <div className="el__text" ref={refAnimation}>
                <h1 className={`el_header`}>{page.title}</h1>

                <div className="el_products_inner_content">
                  <div className="el_products_product" onClick={goToTickets}>
                    <div className="product_image">
                      <img
                        src={
                          CDN_URL +
                          'tickets-page/l2o49ad2uWg5IfVLbH8uLSyj5Zx4Ne1uGlP10ajo.jpeg'
                        }
                      />
                    </div>
                    <div className="product_content">
                      <h2 className="title">{t('tickets')}</h2>
                      <p>{t('tickets short info')}</p>
                    </div>
                  </div>
                  <div className="el_products_product" onClick={goToShop}>
                    <div className="product_image">
                      <img
                        src={
                          CDN_URL +
                          'tickets-page/ZEwz3VLGej5jv76CKNmlVjh19Ij22RLENaS85Db3.jpeg'
                        }
                      />
                    </div>

                    <div className="product_content">
                      <h2 className="title">{t('handmade gifts')}</h2>
                      <p>{t('handmade gifts short info')}</p>
                    </div>
                  </div>
                </div>

                <div className="fixing-positions" />
              </div>
              <CloseButton handleClose={closeClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Tickets.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired
  }).isRequired,
  contRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default Tickets;
