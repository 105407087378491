/* eslint-disable no-console */
import './Home.css';
import config from '../../config';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import myDispatch from '../../store/dispatcher';
import { Contacts, CartContainer, Products, Sections } from '../../containers';
import {
  Head,
  SpeedDial,
  LanguageSwitcher,
  LoadingScreen,
  CartNotification
} from '../../components';
import Tickets from '../../containers/Tickets';

const {
  LANGUAGES,
  LANGUAGE,
  PRODUCTS_FEATURE,
  AVAILABLE_PAGES: {
    homeSectionsIds,
    contactId,
    cartId,
    termsId,
    gdprId,
    homeId,
    ticketsPageId
  }
} = config;

function Home() {
  const contRef = useRef(null);
  const { dispatchHomePage, loader } = myDispatch();
  const pages = useSelector(state => state.data.pages);
  const cartUpdated = useSelector(state => state.cart.cartUpdated);

  useEffect(() => {
    dispatchHomePage();
    const t1 = setTimeout(() => {
      loader(false);
      contRef.current.classList.remove('s--inactive');
    }, 500);
    return () => {
      clearTimeout(t1);
    };
  }, [cartUpdated]);

  const gotoTerms = () => {
    window.location.href = `/${LANGUAGES[LANGUAGE].url}`;
  };

  const gotoGdpr = () => {
    window.location.href = `/${LANGUAGES[LANGUAGE].url_gdpr}`;
  };

  const getPageByOrderId = id => {
    return pages.find(x => x.order == id);
  };

  const getSections = (
    <>
      {pages &&
        homeSectionsIds.map(p => {
          const page = getPageByOrderId(p);
          return (
            page && <Sections key={page.id} page={page} contRef={contRef} />
          );
        })}
    </>
  );

  const getTicketsPage = (
    <>
      {pages && getPageByOrderId(ticketsPageId) && (
        <Tickets contRef={contRef} page={getPageByOrderId(ticketsPageId)} />
      )}
    </>
  );

  const getProductPage = (
    <>
      {PRODUCTS_FEATURE.show &&
        pages &&
        getPageByOrderId(PRODUCTS_FEATURE.productPageId) && (
          <Products
            contRef={contRef}
            page={getPageByOrderId(PRODUCTS_FEATURE.productPageId)}
          />
        )}
    </>
  );

  const getContactsPage = (
    <>
      {pages && getPageByOrderId(contactId) && (
        <Contacts contRef={contRef} page={getPageByOrderId(contactId)} />
      )}
    </>
  );

  const getCartPage = (
    <>
      {pages && getPageByOrderId(cartId) && (
        <CartContainer contRef={contRef} page={getPageByOrderId(cartId)} />
      )}
    </>
  );

  const getTermsPage = (
    <>
      {pages && getPageByOrderId(termsId) && (
        <Sections page={getPageByOrderId(termsId)} contRef={contRef} />
      )}
    </>
  );

  const getGdprPage = (
    <>
      {pages && getPageByOrderId(gdprId) && (
        <Sections page={getPageByOrderId(gdprId)} contRef={contRef} />
      )}
    </>
  );

  return (
    <div className="cont s--inactive" ref={contRef}>
      <LoadingScreen />
      {getPageByOrderId(homeId) && <Head data={getPageByOrderId(homeId)} />}
      <LanguageSwitcher />
      <div className="cont__inner">
        {getSections}
        {getTicketsPage}
        {getProductPage}
        {getContactsPage}
        {getCartPage}
        {getGdprPage}
        {getTermsPage}
        <SpeedDial />
      </div>
      <CartNotification />
      <div className="additional_pages">
        <div className="terms" onClick={gotoTerms}>
          {LANGUAGES[LANGUAGE].text_terms}
        </div>
        <div className="gdpr" onClick={gotoGdpr}>
          {LANGUAGES[LANGUAGE].text_gdpr}
        </div>
      </div>
    </div>
  );
}
export default Home;
