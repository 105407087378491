import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function CartNotification() {
  const cartUpdated = useSelector((state) => state.ui.cartUpdated);
  const { t } = useTranslation();

  return (
    <>
      {cartUpdated && (
        <div className="cart-updated">{t('success add to cart')}</div>
      )}
    </>
  );
}

CartNotification.propTypes = {};

export default CartNotification;
