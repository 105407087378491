import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import WebpIsSupported from '../../utils/checkWebp';
import myDispatch from '../../store/dispatcher';

function ImagePreview({ page }) {
  const { dispatchImageVisibility } = myDispatch();
  const imageVisible = useSelector((state) => state.ui.imageVisible);

  const close = () => {
    dispatchImageVisibility(false);
  }

  return (
    <>
      {imageVisible && (
        <div className="imagePanel">
          {WebpIsSupported() && (
            <picture>
              <source srcSet={page.defaultBanner.replace('jpeg', 'webp')} type="image/webp" />
              <source srcSet={page.defaultBanner} type="image/jpeg" />
              <img alt={page.title} src={page.defaultBanner} />
            </picture>
          )}
          {!WebpIsSupported() && (
            <img alt={page.title} src={page.defaultBanner} />
          )}
          <span className="hide-imagePanel" onClick={close} />
        </div>
      )}
    </>
  );
}

ImagePreview.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired
  }).isRequired,
};

export default ImagePreview;
