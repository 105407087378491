import { combineReducers } from 'redux';
import uiReducer from './ui.reducer';
import dataReducer from './data.reducer';
import cartReducer from './cart.reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  data: dataReducer,
  cart: cartReducer
});

export default rootReducer;
