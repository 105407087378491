/* eslint-disable react/no-danger */
import './CartContainer.css';

import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Head,
  Cart,
  OrderDetails,
  Title,
  PageBackground,
  CloseButton
} from '../../components';
import { pageCtrl } from '../../utils/common';
import myDispatch from '../../store/dispatcher';

function CartContainer({ contRef, page }) {
  const params = useParams();
  const elRef = useRef(null);
  const refAnimation = useRef(null);
  const [loadState, setLoadState] = useState(false);
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const { dispatchCloseBtnVisibility } = myDispatch();
  const { t } = useTranslation();
  const cmd = pageCtrl({ elRef, contRef });
  let timerOnLoad = null;

  const products = useSelector(selector => selector.cart.products);

  useEffect(() => {
    setIsCurrentPage(params.page === page.slug);
    const t1 = setTimeout(setLoadState(true), 150);
    if (isCurrentPage) dispatchCloseBtnVisibility(true);
    return () => {
      clearTimeout(t1);
      clearTimeout(timerOnLoad);
      dispatchCloseBtnVisibility(false);
      setIsCurrentPage(false);
    };
  }, [params]);

  const openAnimation = (cb = null) => {
    if (elRef.current.classList.contains('s--active')) return;
    if (cb !== null) cb();
    cmd.show();
    setTimeout(() => {
      setLoadState(true);
      refAnimation.current.classList.add('active');
    }, 1.5e3);
  };

  const onReload = () => (isCurrentPage ? openAnimation() : null);

  const changeUrl = () => {
    cmd.goTo(`/${page.slug}`);
  };

  timerOnLoad = setTimeout(onReload, 2e2);

  const elClick = () => openAnimation(changeUrl);

  const closeClick = () => cmd.close({ setIsCurrentPage, setLoadState });

  return (
    <div className="el" ref={elRef} onClick={elClick}>
      {loadState && <Head data={page} />}
      <div className="el__overflow">
        <div className="el__inner">
          <PageBackground banner={page.defaultBanner} />
          <Title text={page.title} />
          {loadState && (
            <div className="el__content">
              <div ref={refAnimation} className="el__text">
                <h1>{page.title}</h1>
                {products.length < 1 && <p>{t('cart is empty')}</p>}
                {products.length > 0 && (
                  <div className="two-columns">
                    <div className="table">
                      <h3>{t('products in cart')}</h3>
                      <OrderDetails />
                    </div>
                    <div className="user-details">
                      <h3>{t('shipping details')}</h3>
                      <Cart />
                    </div>
                  </div>
                )}
              </div>
              <CloseButton handleClose={closeClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CartContainer.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired
  }).isRequired,
  contRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default CartContainer;
