/* eslint-disable react/no-array-index-key */
import './OrderDetails.css';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';

import WebpIsSupported from '../../utils/checkWebp';
import myDispatch from '../../store/dispatcher';

function OrderDetails() {
  const { dispatchRemoveProductFromCart } = myDispatch();
  const { t } = useTranslation();
  const products = useSelector((selector) => selector.cart.products);

  const handleOnClick = (productID) => {
    const removedItem = products.splice(productID, 1);
    dispatchRemoveProductFromCart({ products, ri: removedItem });
  };

  useEffect(() => {}, [products]);

  const ImagePreviewHere = ({ image, title }) => (
    <div className="image">
      {WebpIsSupported() && (
        <picture>
          <source srcSet={image.replace('jpeg', 'webp').replace('png', 'webp').replace('jpg', 'webp')} type="image/webp" />
          <source srcSet={image} type="image/jpeg" />
          <img
            src={image}
            alt={title}
          />
        </picture>
      )}
      {!WebpIsSupported() && (
        <img
          src={image}
          alt={title}
        />
      )}
    </div>
  );

  ImagePreviewHere.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  };

  const Price = ({ price }) => (
    <div>
      {`${price} ${t('currency sign')}`}
    </div>
  );

  Price.propTypes = {
    price: PropTypes.string.isRequired
  };

  return (
    <ul>
      {products.map((p, index) => (
        <li key={`${index}-${p.title}`}>
          <ImagePreviewHere image={p.image} title={p.title} />
          <div className="details">
            <div>{p.title}</div>
            <Price price={p.price} />
          </div>
          <div className="remove" onClick={() => handleOnClick(index)}>
            <DeleteIcon />
          </div>
        </li>
      ))}
    </ul>
  );
}

export default OrderDetails;
