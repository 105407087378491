import './Products.css';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import PropTypes from 'prop-types';

import {
  Product,
  Head,
  PageBackground,
  Title,
  CloseButton
} from '../../components';
import { pageCtrl } from '../../utils/common';
import myDispatch from '../../store/dispatcher';
import cfg from '../../config';

function Products({ contRef, page }) {
  const params = useParams();
  const elRef = useRef(null);
  const {
    dispatchProductsPage,
    dispatchQuickmenuVisibility,
    dispatchCloseBtnVisibility
  } = myDispatch();
  const listProdRef = useRef(null);
  const innerRef = useRef(null);
  const [loadState, setLoadState] = useState();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const cmd = pageCtrl({ elRef, contRef });
  let timerOnLoad = null;

  const products = useSelector(state => state.data.products);

  const isProductPage = () => {
    if (params.product && params.product.length > 0) {
      dispatchQuickmenuVisibility(false);
      setLoadState(true);
      setIsHidden(true);
    } else {
      dispatchQuickmenuVisibility(true);
      setIsHidden(false);
    }
  };

  useEffect(() => {
    setIsCurrentPage(params.page === page.slug);
    isProductPage();
    dispatchCloseBtnVisibility(true);
    return () => {
      clearTimeout(timerOnLoad);
      dispatchCloseBtnVisibility(true);
      setIsCurrentPage(false);
    };
  }, [params]);

  const setActiveClass = () => {
    dispatchProductsPage();
    cmd.show();
  };

  const openAnimation = (cb = null) => {
    if (elRef.current.classList.contains('s--active')) return;
    if (cb !== null) cb();
    setActiveClass();
    setTimeout(() => {
      setLoadState(true);
      innerRef.current.classList.add('active');
      listProdRef.current.classList.add('full-size');
    }, 2e3);
  };

  const onReload = () => (isCurrentPage ? openAnimation() : null);

  const changeUrl = () => {
    cmd.goTo(`/${page.slug}`);
  };

  timerOnLoad = setTimeout(onReload, 2e2);

  const elClick = () => openAnimation(changeUrl);

  const closeClick = () => cmd.close({ setIsCurrentPage, setLoadState });

  return (
    <div className="el" ref={elRef} onClick={elClick}>
      {loadState && <Head data={page} />}
      <div className="el__overflow">
        <div className="el__inner">
          <PageBackground banner={page.defaultBanner} />
          <Title text={page.title} />
          {loadState && (
            <div className="el__content">
              <div
                className={`active products ${
                  isHidden ? 'selected-product' : ''
                }`}
              >
                <h1 className={`el_header ${isHidden ? ' hidden' : ''}`}>
                  {page.title}
                </h1>
                <div className="el__products" ref={listProdRef}>
                  <div ref={innerRef} className="el_products_inner">
                    {products &&
                      products.length > 0 &&
                      products.map(p => (
                        <div
                          className="lazy-load"
                          key={p.model}
                          offsetVertical={0}
                        >
                          <Product
                            key={p.model}
                            isHidden={isHidden}
                            setIsHidden={setIsHidden}
                            innerRef={innerRef}
                            isCurrentProductLoaded={params.product === p.slug}
                            product={p}
                            pageSlug={page.slug}
                          />
                        </div>
                      ))}
                    <div className="tickets_info-container">
                      <div
                        className="tickets_info"
                        dangerouslySetInnerHTML={{ __html: page.content }}
                      />
                    </div>
                  </div>
                </div>
                <div className="fixing-positions" />
              </div>
              <CloseButton handleClose={closeClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Products.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired
  }).isRequired,
  contRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default Products;
