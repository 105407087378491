import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const MyContext = React.createContext(null);

function MyProvider({ children }) {
  const contRef = useRef(null);
  const innerRef = useRef(null);
  const elRef = useRef(null);
  const prRef = useRef(null);
  const contactRef = useRef(null);
  const cartRef = useRef(null);

  const initialValues = {
    contRef,
    innerRef,
    elRef,
    prRef,
    contactRef,
    cartRef,
  };

  return (
    <MyContext.Provider value={initialValues}>
      {children}
    </MyContext.Provider>
  );
}

MyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export { MyContext, MyProvider };
