/* eslint-disable react/no-danger */
import './Contacts.css';

import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Head,
  ContactForm,
  PageBackground,
  Title,
  CloseButton
} from '../../components';
import { pageCtrl } from '../../utils/common';
import myDispatch from '../../store/dispatcher';

function Contacts({ contRef, page }) {
  const params = useParams();
  const elRef = useRef(null);
  const refAnimation = useRef(null);
  const [loadState, setLoadState] = useState(false);
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const { dispatchCloseBtnVisibility } = myDispatch();
  const { t } = useTranslation();
  const cmd = pageCtrl({ elRef, contRef });
  let timerOnLoad = null;

  useEffect(() => {
    setIsCurrentPage(params.page === page.slug);
    const t1 = setTimeout(setLoadState(true), 150);
    if (isCurrentPage) dispatchCloseBtnVisibility(true);
    return () => {
      clearTimeout(t1);
      clearTimeout(timerOnLoad);
      dispatchCloseBtnVisibility(false);
      setIsCurrentPage(false);
    };
  }, [params]);

  const openAnimation = (cb = null) => {
    if (elRef.current.classList.contains('s--active')) return;
    if (cb !== null) cb();
    cmd.show();
    setTimeout(() => {
      setLoadState(true);
      refAnimation.current.classList.add('active');
    }, 1.5e3);
  };

  const onReload = () => (isCurrentPage ? openAnimation() : null);

  const changeUrl = () => {
    cmd.goTo(`/${page.slug}`);
  };

  timerOnLoad = setTimeout(onReload, 2e2);

  const elClick = () => openAnimation(changeUrl);

  const closeClick = () => cmd.close({ setIsCurrentPage, setLoadState });

  return (
    <div className="el" ref={elRef} onClick={elClick}>
      {loadState && <Head data={page} />}
      <div className="el__overflow">
        <div className="el__inner">
          <PageBackground banner={page.defaultBanner} />
          <Title text={page.title} />
          {loadState && (
            <div className="el__content">
              <div ref={refAnimation} className="el__text">
                <h1>{page.title}</h1>
                <div className="three-columns">
                  <div className="form">
                    <h3>{t('send request')}</h3>
                    <ContactForm />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: page.content }} />
                  <div className="map">
                    <h3>{t('find our products here')}</h3>
                    <iframe
                      style={{ padding: 0, border: 0 }}
                      height="400"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2905.178368359354!2d27.513728015485327!3d43.26863887913636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDPCsDE2JzA3LjEiTiAyN8KwMzAnNTcuMyJF!5e0!3m2!1sen!2sbg!4v1624193235682!5m2!1sen!2sbg"
                      allowFullScreen=""
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <CloseButton handleClose={closeClick} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Contacts.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired
  }).isRequired,
  contRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default Contacts;
