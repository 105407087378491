import './SpeedDial.css';

import React, { useState } from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import EmailIcon from '@material-ui/icons/Email';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import WebpIsSupported from '../../utils/checkWebp';
const { AVAILABLE_PAGES, CDN_URL } = config;

function SpeedDial() {
  const products = useSelector(selector => selector.cart.products);
  const pages = useSelector(selector => selector.data.pages);
  const quickmenuVisible = useSelector(
    selector => selector.ui.quickmenuVisible
  );
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  AVAILABLE_PAGES;
  const goToContacts = () => {
    window.location.href = `/${
      pages.find(page => page.order === AVAILABLE_PAGES.contactId)?.slug
    }`;
  };

  const goToCart = () => {
    window.location.href = `/${
      pages.find(page => page.order === AVAILABLE_PAGES.cartId)?.slug
    }`;
  };

  const images = {
    webp: `${CDN_URL}storage/obshi/yvWOXuhrNEs83EDWihnk0DIbwBNUH0vvoyx1FdQD.webp`,
    png: `${CDN_URL}storage/obshi/yvWOXuhrNEs83EDWihnk0DIbwBNUH0vvoyx1FdQD.png`
  };

  const baseItems = [
    {
      name: 'cart',
      method: goToCart,
      icon: <ShoppingCartIcon />
    },
    {
      name: 'contacts',
      method: goToContacts,
      icon: <EmailIcon />
    }
  ];

  const socialMedias = [
    {
      name: 'Facebook',
      href:
        'https://www.facebook.com/The-magic-of-Bulgarian-Rose-%D0%9C%D0%B0%D0%B3%D0%B8%D1%8F%D1%82%D0%B0-%D0%BD%D0%B0-%D0%91%D1%8A%D0%BB%D0%B3%D0%B0%D1%80%D1%81%D0%BA%D0%B0%D1%82%D0%B0-%D1%80%D0%BE%D0%B7%D0%B0-864577240375572/',
      icon: <FacebookIcon />
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/themagicofbulgarianrose/',
      icon: <InstagramIcon />
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/channel/UCksZdfQEYC6PwSmS1FofvUQ',
      icon: <YouTubeIcon />
    }
  ];

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleClickMenuItems = socialMedia => {
    ReactGA.event({
      category: 'Page',
      action: 'Click Social Media',
      label: socialMedia
    });
    handleOpenClose();
  };

  const showMenuBtn = (
    <button
      type="button"
      className="speed-dial__button speed-dial__button--primary"
      onClick={handleOpenClose}
    >
      {WebpIsSupported() && (
        <picture>
          <source srcSet={images.webp} type="image/webp" />
          <source srcSet={images.png} type="image/png" />
          <img alt="Logo" src={images.png} width="50px" rel="Button" />
        </picture>
      )}
      {!WebpIsSupported() && (
        <img alt="Logo" src={images.png} width="50px" rel="Button" />
      )}
    </button>
  );

  const showSocialIcons = (
    <>
      {socialMedias.map(el => (
        <div key={el.name} className="speed-dial__button--holder">
          {t(el.name)}
          <a
            type="button"
            onClick={() => handleClickMenuItems(el.name)}
            target="_blank"
            href={el.href}
            rel="noopener noreferrer"
            className="speed-dial__button"
          >
            {el.icon}
          </a>
        </div>
      ))}
    </>
  );

  const showBaseItems = (
    <>
      {baseItems.map(el => (
        <div key={el.name} className="speed-dial__button--holder">
          {t(el.name)}
          <button
            type="button"
            onClick={el.method}
            className="speed-dial__button"
          >
            {el.icon}
          </button>
        </div>
      ))}
    </>
  );

  return (
    <>
      {quickmenuVisible && (
        <>
          <div className={`backdrop ${open ? 'show' : ''}`} />
          <div className={`speed-dial ${open ? 'speed-dial--active' : ''}`}>
            {products.length > 0 && (
              <span className="badge">{products.length}</span>
            )}
            {showMenuBtn}
            <div className="speed-dial__options">
              {showBaseItems}
              {showSocialIcons}
            </div>
          </div>
        </>
      )}
    </>
  );
}

SpeedDial.propTypes = {};

export default SpeedDial;
