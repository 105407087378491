/* eslint-disable no-unused-vars */
import config from '../config';
import flattenObject from '../utils/mapper';

const { LANGUAGE, API_URL } = config;

const URL_WITH_LANGUAGE = `${API_URL}${LANGUAGE}`;

const abortController = new AbortController();

const getProducts = async () => {
  const data = {
    products: [],
    categories: []
  };
  const response = await fetch(`${URL_WITH_LANGUAGE}/products`, {
    signal: abortController.signal
  })
    .then(res => res.json())
    .then(json => {
      const { products, categories } = json;
      products.map((p, _i) => {
        data.products.push(
          flattenObject(p, ['children', 'author'], ['gallery'])
        );
        return null;
      });
      categories.map((c, _i) => {
        data.categories.push(flattenObject(c));
        return null;
      });
      return data;
    });
  return response;
};

export default {
  getProducts
};
