/* eslint-disable no-case-declarations */
import {
  ADD_PRODUCT_TO_CART_SUCCESS,
  PRODUCT_REMOVED_FROM_CART_SUCCESS,
  REQUEST_FORM_SENT_SUCCESSFULLY,
} from '../action-types';

const cartReducer = (
  state = {
    products: [],
    total: 0,
    user_details: {},
  },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_SUCCESS:
      const cartTotal = (+state.total) + (+action.payload.price);
      const productData = {
        id: action.payload.id,
        title: action.payload.title,
        price: action.payload.price,
        image: action.payload.defaultBanner
      };
      return {
        ...state,
        products: [...state.products, productData],
        total: cartTotal
      };
    case PRODUCT_REMOVED_FROM_CART_SUCCESS:
      const cartTotal2 = (+state.total) - (+action.payload.ri.price);
      return {
        ...state,
        products: JSON.parse(JSON.stringify(action.payload.products)),
        total: cartTotal2
      };
    case REQUEST_FORM_SENT_SUCCESSFULLY:
      return {
        ...state,
        products: [],
        total: 0,
        requestSent: true
      };
    default:
      return state;
  }
};
export default cartReducer;
