export default {
  GOOGLE_ANALYTICS: 'UA-159452718-1',
  FB_PIXEL_ID: '3228121923968989',
  FB_PAGEID: '864577240375572',
  FB_APPID: '194975758546896',
  API_URL: 'https://admin.rosarium.bg/api/',
  CDN_URL: 'https://cdn.rosarium.bg/',
  LANGUAGE: 'bg',
  DEFAULT_LANG: 'bg',
  NOTIFICATION_TIMEOUT: 5000,
  AVAILABLE_PAGES: {
    homeId: 0,
    contactId: 40,
    cartId: 50,
    termsId: 60,
    gdprId: 70,
    homeSectionsIds: [1, 2, 3],
    ticketsPageId: 5
  },
  PRODUCTS_FEATURE: {
    show: true,
    productPageId: 4
  },
  LOADING_SCREEN_IMAGE:
    'https://cdn.rosarium.bg/obshi/rVjkIugUUILa9kaENXH8CrZi7Dksg3igbrSQVmeu.png',
  SLIDER_SETTINGS: {
    showThumbs: false,
    showStatus: false,
    infiniteLoop: true,
    autoPlay: true,
    interval: 3000,
    stopOnHover: true
  },
  LANGUAGES: {
    bg: {
      title: 'БГ',
      domain: 'www.rosarium.bg',
      default_category: 'energiya-v-bizhu',
      text_terms: 'Общи условия',
      text_gdpr: 'Поверителност',
      url: 'obshchi-usloviya',
      url_gdpr: 'gdpr'
    },
    en: {
      title: 'EN',
      domain: 'en.rosarium.bg',
      default_category: 'energy-in-jewelry',
      text_terms: 'Terms and conditions',
      text_gdpr: 'Privacy policy',
      url: 'terms-and-conditions',
      url_gdpr: 'gdpr'
    }
  }
};
