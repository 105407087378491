/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable-next-line array-callback-return */
import { put, takeLatest, all, delay, call } from 'redux-saga/effects';
import {
  pageReceivedSuccessfully,
  productsReceivedSuccessfully,
  categoriesReceivedSuccessfully,
  productAddedToCartSuccessfully,
  productRemovedFromCartSuccessfully,
  hideCartAlertsSuccessfully,
  contactFormSentSuccessfully,
  contactFormSentFailed,
  requestFormSentSuccessfully,
  requestFormSentFailed,
  showCartAlertsSuccessfully
} from '../actions';
import {
  REQUEST_FORM_REQUEST,
  CONTACT_FORM_REQUEST,
  REMOVE_PRODUCT_TO_CART,
  ADD_PRODUCT_TO_CART,
  GET_PRODUCTS_AND_CATEGORIES,
  GET_INITIAL_DATA
} from '../action-types';
import config from '../../config';
import { prodSrv, pageSrv } from '../../services';

const { NOTIFICATION_TIMEOUT } = config;

function* fetchPage(action) {
  const data = yield call(pageSrv.getPages, action.payload);
  yield put(pageReceivedSuccessfully(data));
}

function* fetchProducts() {
  const data = yield call(prodSrv.getProducts);
  yield put(productsReceivedSuccessfully(data.products));
  yield put(categoriesReceivedSuccessfully(data.categories));
}

function* putProductToCart(action) {
  yield put(productAddedToCartSuccessfully(action.payload));
  yield put(showCartAlertsSuccessfully());
  yield delay(NOTIFICATION_TIMEOUT);
  yield put(hideCartAlertsSuccessfully());
}

function* removeProductToCart(action) {
  yield put(productRemovedFromCartSuccessfully(action.payload));
  yield put(showCartAlertsSuccessfully());
  yield delay(NOTIFICATION_TIMEOUT);
  yield put(hideCartAlertsSuccessfully());
}

function* sendContactForm(action) {
  const data = yield call(pageSrv.sendContactForm, action.payload);
  if (data.status === 'ok')
    yield put(contactFormSentSuccessfully(action.payload));
  else yield put(contactFormSentFailed(action.payload));
  yield delay(NOTIFICATION_TIMEOUT);
  yield put(hideCartAlertsSuccessfully());
}

function* requestFormRequest(action) {
  const data = yield call(pageSrv.sendRequestForm, action.payload);
  if (data.status === 'ok')
    yield put(requestFormSentSuccessfully(action.payload));
  else yield put(requestFormSentFailed(action.payload));
  yield delay(NOTIFICATION_TIMEOUT);
  yield put(hideCartAlertsSuccessfully());
}

// Register watchers
function* actionWatcher() {
  yield takeLatest(GET_INITIAL_DATA, fetchPage);
  yield takeLatest(GET_PRODUCTS_AND_CATEGORIES, fetchProducts);
  yield takeLatest(ADD_PRODUCT_TO_CART, putProductToCart);
  yield takeLatest(REMOVE_PRODUCT_TO_CART, removeProductToCart);
  yield takeLatest(CONTACT_FORM_REQUEST, sendContactForm);
  yield takeLatest(REQUEST_FORM_REQUEST, requestFormRequest);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
