import './Sections.css';

import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Head,
  ImagePreview,
  PageBackground,
  Title,
  PageContent
} from '../../components';
import { pageCtrl } from '../../utils/common';
import myDispatch from '../../store/dispatcher';

function Sections({ page, contRef }) {
  const params = useParams();
  const elRef = useRef(null);
  const refAnimation = useRef(null);
  const [loadState, setLoadState] = useState(false);
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const { dispatchCloseBtnVisibility } = myDispatch();
  const cmd = pageCtrl({ elRef, contRef });
  let timerOnLoad = null;

  useEffect(() => {
    setIsCurrentPage(params.page === page.slug);
    const t1 = setTimeout(setLoadState(true), 1.5e2);
    if (isCurrentPage) dispatchCloseBtnVisibility(true);
    return () => {
      clearTimeout(t1);
      clearTimeout(timerOnLoad);
      dispatchCloseBtnVisibility(false);
      setIsCurrentPage(false);
    };
  }, [params]);

  const openAnimation = (cb = null) => {
    if (elRef.current.classList.contains('s--active')) return;
    if (cb !== null) cb();
    cmd.show();
    setTimeout(() => {
      setLoadState(true);
      refAnimation.current.classList.add('active');
      cmd.accordion();
    }, 1.1e3);
  };

  const onReload = () => (isCurrentPage ? openAnimation() : null);

  const changeUrl = () => {
    cmd.goTo(`/${page.slug}`);
  };

  timerOnLoad = setTimeout(onReload, 2e2);

  const elClick = () => openAnimation(changeUrl);

  const handleClose = () => {
    cmd.goTo('/');
    cmd.hide();
    setIsCurrentPage(false);
    setLoadState(false);
  };

  return (
    <div className="el" ref={elRef} onClick={elClick}>
      {loadState && <Head data={page} />}
      <div className="el__overflow">
        <div className="el__inner">
          <PageBackground banner={page.defaultBanner} />
          <Title text={page.title} />
          {loadState && (
            <PageContent
              title={page.title}
              refAnimation={refAnimation}
              content={page.content}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
      <ImagePreview page={page} />
    </div>
  );
}

Sections.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultBanner: PropTypes.string.isRequired
  }).isRequired,
  contRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default Sections;
