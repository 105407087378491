import './Product.css';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ProductCard,
  ProductDetails,
  ProductDetailsLoading,
  ProductLoading
} from '.';
import myDispatch from '../../store/dispatcher';
import { goTo, trackEvent } from '../../utils/common';

function Product({
  product,
  isCurrentProductLoaded,
  isHidden,
  setIsHidden,
  innerRef,
  pageSlug
}) {
  const { dispatchQuickmenuVisibility } = myDispatch();
  const prRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleImageLoad = () => setIsImageLoading(false);

  const handleImageError = () => setIsImageLoading(true);

  const isLoaded = timeout => {
    innerRef.current.scrollTo(0, 0);
    setTimeout(() => {
      setLoaded(true);
      setIsHidden(true);
      innerRef.current.style = 'overflow-y: hidden;';
    }, 0);
  };

  const prClick = () => {
    goTo(`/${pageSlug}/${product.slug}`);
    trackEvent(product.title, 'Products', 'Select Product');
    isLoaded(10);
    dispatchQuickmenuVisibility(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsImageLoading(false);
    }, 250);
    setDetailsLoading(true);
    if (isCurrentProductLoaded) isLoaded(1e3);
  }, []);

  const displayProductCard = (
    <>
      {!isHidden && isImageLoading && <ProductLoading isHidden={isHidden} />}
      {!isHidden && (
        <ProductCard
          product={product}
          isImageLoading={isImageLoading}
          isHidden={isHidden}
          prRef={prRef}
          prClick={prClick}
          handleImageLoad={handleImageLoad}
          handleImageError={handleImageError}
        />
      )}
    </>
  );

  const displayProductDetails = (
    <>
      {loaded && (
        <>
          {detailsLoading && <ProductDetailsLoading />}
          <ProductDetails
            product={product}
            setLoaded={setLoaded}
            setDetailsLoading={setDetailsLoading}
            detailsLoading={detailsLoading}
            setIsHidden={setIsHidden}
            isHidden={isHidden}
            isLoaded={isCurrentProductLoaded}
            innerRef={innerRef}
            pageSlug={pageSlug}
          />
        </>
      )}
    </>
  );

  return (
    <>
      {displayProductCard}
      {displayProductDetails}
    </>
  );
}

Product.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }).isRequired,
  pageSlug: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  isCurrentProductLoaded: PropTypes.bool.isRequired,
  setIsHidden: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired
};

export default Product;
