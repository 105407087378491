/* eslint-disable no-case-declarations */
import {
  HIDE_CART_ALERT,
  CONTACT_FORM_SENT_SUCCESSFULLY,
  CONTACT_FORM_SENT_FAILED,
  REQUEST_FORM_SENT_SUCCESSFULLY,
  REQUEST_FORM_SENT_FAILED,
  SET_QUICKMENU_VISIBILITY,
  SET_MESSENGER_VISIBILITY,
  SET_LOADER_VISIBILITY,
  SET_IMAGE_VISIBILITY,
  SET_CLOSE_BTN_VISIBILITY,
  SHOW_CART_ALERT,
} from '../action-types';

const initialState = {
  loading: true,
  cartUpdated: false,
  messengerVisible: true,
  quickmenuVisible: true,
  closeBtnVisible: true,
  imageVisible: false,
  formSent: true,
  requestSent: true,
};

const uiReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_LOADER_VISIBILITY:
      return { ...state, loading: action.payload };
    case SET_MESSENGER_VISIBILITY:
      return { ...state, messengerVisible: action.payload };
    case SET_QUICKMENU_VISIBILITY:
      return { ...state, quickmenuVisible: action.payload };
    case SET_CLOSE_BTN_VISIBILITY:
      return { ...state, closeBtnVisible: action.payload };
    case SET_IMAGE_VISIBILITY:
      return { ...state, imageVisible: action.payload };
    case SHOW_CART_ALERT:
      return { ...state, cartUpdated: true };
    case HIDE_CART_ALERT:
      return { ...state, cartUpdated: false };
    case CONTACT_FORM_SENT_SUCCESSFULLY:
      return { ...state, formSent: true };
    case CONTACT_FORM_SENT_FAILED:
      return { ...state, formSent: true };
    case REQUEST_FORM_SENT_SUCCESSFULLY:
      return { ...state, requestSent: true };
    case REQUEST_FORM_SENT_FAILED:
      return { ...state };
    default:
      return state;
  }
};
export default uiReducer;
