/* eslint-disable no-unused-vars */
import config from '../config';
import flattenObject from '../utils/mapper';

const { LANGUAGE, API_URL } = config;

const URL_WITH_LANGUAGE = `${API_URL}${LANGUAGE}`;

const abortController = new AbortController();

const getPages = async page => {
  const data = [];
  const response = await fetch(`${URL_WITH_LANGUAGE}/pages/${page}`, {
    signal: abortController.signal
  })
    .then(res => res.json())
    .then(json => {
      data.push(flattenObject(json, ['children', 'author']));
      Object.keys(json.children).map((c, _i) => {
        data.push(flattenObject(json.children[c], ['children', 'author']));
        return null;
      });
      return data;
    });
  data.sort((a, b) => a.order - b.order);
  return response;
};

const sendContactForm = async data => {
  const requestOption = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    signal: abortController.signal
  };
  const json = await fetch(
    `${API_URL}contact-form`,
    requestOption
  ).then(response => response.json());
  return json;
};

const sendRequestForm = async data => {
  const requestOption = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
    signal: abortController.signal
  };
  const json = await fetch(
    `${API_URL}make-order`,
    requestOption
  ).then(response => response.json());
  return json;
};

export default {
  getPages,
  sendContactForm,
  sendRequestForm
};
